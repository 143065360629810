.p-dropdown {
    width: 100% !important
}
input.p-dropdown-label {
    border: none;
    position: absolute;
    width:100%;
    padding: 0 5px;
}
.justify-content-center {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 10px;
    border: 1px solid var(--black-25, #CBCDCE);
    background: var(--white, #FFF);
    font-family: var(--font-family-regular)
}
.p-dropdown-trigger {
    width: 100%;
    height: 24px;
    flex-shrink: 0;
    color: var(--primary-100);
    position: relative;
    /* float: right; */
    padding-left: 90%;
}
/*.letters .p-dropdown-trigger {*/
/*    width: 130% !important;*/
/*}*/
/*.process .p-dropdown-trigger-icon {*/
/*    margin-left: 90% !important;*/
/*}*/
.dropdown-input-content > .p-dropdown-trigger {
    width: 100%;
    height: 24px;
    flex-shrink: 0;
    color: var(--primary-100);
    position: relative;
    /* float: right; */
    padding-left: 300px;
    align-items: unset!important;
}
.p-datepicker {
    width: 280px!important;
    border-radius: 10px;
    background: var(--white);
    box-shadow: 0px 0px 14.681px 0px rgba(0, 0, 0, 0.30);
    padding: 10px 0 0 0;
    margin: 15px 0 0 -40px;
    font-family: var(--font-family-regular);
    font-size: 14px!important;
    min-width: unset !important;
}
.p-dropdown-items-wrapper {
    border-radius: 10px;
    background: var(--white, #FFF);
    box-shadow: 0px 0px 14.681px 0px rgba(0, 0, 0, 0.30);
    padding: 10px 0 0 0;
    margin: 15px 0 0 -15px;
    font-family: var(--font-family-regular);
    font-size: 16px!important;
    font-weight: 700!important;
}
.p-dropdown-panel {
    width: 200px
}
.p-dropdown-item {
    padding: 10px 0;
}
.p-dropdown-items {
    padding-left:1.5rem !important;
}
.p-calendar .p-inputtext {
    border:none;
    background: transparent;
    cursor: pointer;
}
.p-datepicker-group-container {
    padding: 0 15px 10px 15px
}
.p-datepicker-next {
    border: none;
    background: transparent;
    width: 48px;
    height: 48px;
    color: var(--primary-75)
}
.p-datepicker-prev {
    border: none;
    background: transparent;
    width: 48px;
    height: 48px;
    color: var(--primary-75)
}
.p-link {
    padding: 0 5px
}
.p-datepicker-other-month {
    color: var(--black-25, #CBCDCE);
    font-family: var(--font-family-regular);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.36px;
}
.p-datepicker table td > span.p-highlight {
    color: var(--white);
    background: var(--primary-100);
}
.padding-right-unset .p-datepicker {
    left: 64% !important
}

.p-datepicker table td > span {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    transition: box-shadow 0.2s;
    border: 1px solid transparent;
}
.calendar-content {
    display: flex;
    padding: 9px 16px 9px 0!important;
    border-radius: 10px;
    border: 1px solid var(--black-25, #CBCDCE);
    background: var(--white, #FFF);
    font-family: var(--font-family-regular);
}
.letters .calendar-content {
    height: 50px;
    padding: 12px 16px 0 0!important;
}
.calendar-content-valid {
    display: flex;
    padding: 9px 16px 9px 0!important;
    border-radius: 10px;
    border: 1px solid var(--black-75, #CBCDCE);
    background: var(--white, #FFF);
    font-family: var(--font-family-regular);
}
.dropdown-content {
    display: flex;
    padding: 12px 16px 12px 10px!important;
    border-radius: 10px;
    border: 1px solid var(--black-25, #CBCDCE);
    background: var(--white, #FFF);
    font-family: var(--font-family-regular);
}
.dropdown-input-content {
    display: flex;
    padding: 6px 16px 6px 10px!important;
    border-radius: 10px;
    border: 1px solid var(--black-25, #CBCDCE);
    background: var(--white, #FFF);
    font-family: var(--font-family-regular);
}
.dropdown-input-content-valid {
    display: flex;
    padding: 6px 16px 6px 10px!important;
    border-radius: 10px;
    border: 1px solid var(--black-75, #CBCDCE);
    background: var(--white, #FFF);
    font-family: var(--font-family-regular);
}
.label-dropdown {
    font-size: 12px;
    font-family: var(--font-family-regular);
    color: var(--black-50);
    padding: 0 0 0 5px;
}
.p-calendar {
    max-width: 120% !important;
    min-width: 120% !important;
}
.padding-calendar-single {
    padding-left: unset;
    padding-top: 14px
}
.padding-input-dropdown {
    padding-left: unset;
    padding-top: 20px
}
.form-spacing-calendar {
    padding: 8px 0 10px 4.2%;
}
.edit-form .form-spacing-calendar {
    padding: 8px 0 10px 5%;
}
.modal-body .form-spacing-calendar {
    padding: 8px 0 10px 5%;
}
.certificate-dropdown-group .p-dropdown-trigger {
    padding-left: 90%!important;
}
.p-dropdown-items .p-highlight {
    color: var(--primary-100) !important
}
.datepicker .p-highlight {
    color: var(--white) !important
}
.document-form .p-dropdown-trigger {
    width: 120% !important;
}
.calendar-dropdown .p-dropdown-trigger {
    width: 120% !important;
}
.template-list .p-dropdown-trigger {
    width: 120% !important;
}
.template-list .p-dropdown-panel {
    width: 35% !important;
}
.darkBG .p-dropdown-panel {
    z-index: 1!important;
}
.padding-input-caret {
    text-align: right !important;
}
.body-text-form .p-calendar .p-inputtext {
    padding-left: 10px
}
.p-datepicker table {
    text-align: center
}
.p-datepicker-multiple-month{
    width: 540px !important;;
}
.p-datepicker-group {
    padding: 0 10px
}
.to-calendar .p-datepicker {
    left: 70% !important;
}
.p-dropdown-item:hover {
    border: none;
    outline: none
}
