.app-body-send-process .document-form {
    background: var(--white);
    border-radius: 10px;
}
.app-body-send-process .justify-content-center {
    padding: 12px 16px 2px 16px;
    background-color: transparent!important;
    border: 1px solid var(--black-75)
}
.app-body-send-process .justify-content-center-error {
    padding: 12px 16px 2px 16px;
    background-color: transparent!important;
    border: 1px solid var(--black-25)
}
.p-multiselect {
    /*background: #ffffff;*/
    /*border: 1px solid #d1d5db;*/
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
    width: 100%
}
.p-multiselect:not(.p-disabled):hover {
    border-color: #06b6d4;
}
.p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a5f3fc;
    border-color: #06b6d4;
}
.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.75rem;
}
.p-multiselect .p-multiselect-label {
    padding:1.1rem 0 0.2rem 0;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
    color: #6b7280;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.375rem 0.75rem;
    margin-right: 0.5rem;
    background: #e5e7eb;
    color: #4b5563;
    border-radius: 16px;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: var(--primary-100);
    width: 3rem;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.p-multiselect .p-multiselect-clear-icon {
    color: #6b7280;
    right: 3rem;
}
.p-multiselect.p-invalid.p-component {
    border-color: #e24c4c;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.375rem 0.75rem;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
    padding: 0.75rem 0.75rem;
}
.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.75rem;
}
.p-multiselect-panel {
    background: #ffffff;
    color: #4b5563;
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    top: 255.6px !important;
}
.p-multiselect-panel .p-multiselect-header {
    padding: 0.75rem 1.25rem;
    /*border-bottom: 1px solid #e5e7eb;*/
    color: #374151;
    background: transparent;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
    margin: 0 0;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.75rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    right: 93%;
    color: var(--primary-100);
    height: 20px;
    width: 20px
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    width: 2rem;
    height: 2rem;
    color: #6b7280;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    display: none
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: #374151;
    border-color: transparent;
    background: #f3f4f6;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus-visible {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a5f3fc;
}
.p-multiselect-panel .p-multiselect-items {
    padding: 0.75rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.75rem 1.25rem;
    border: 0 none;
    color: var(--black-100);
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
    font-family: var(--font-family-regular);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: var(--black-75);
    background: transparent;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #4b5563;
    background: #f3f4f6;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    /*box-shadow: inset 0 0 0 0.15rem #a5f3fc;*/
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    margin: 0;
    padding: 0.75rem 1.25rem;
    color: #374151;
    background: #ffffff;
    font-weight: 700;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    padding: 0.75rem 1.25rem;
    color: #4b5563;
    background: transparent;
}
.p-input-filled .p-multiselect {
    background: #f3f4f6;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: #f3f4f6;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: #ffffff;
}

.p-checkbox {
    width: 22px;
    height: 22px;
}
.p-checkbox .p-checkbox-box {
    border: 2px solid #d1d5db;
    background: #ffffff;
    width: 22px;
    height: 22px;
    color: #4b5563;
    border-radius: 6px;
    /*transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;*/
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #ffffff;
    font-size: 14px;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
    width: 14px;
    height: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: transparent;
    background: var(--primary-100);
}
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: transparent;
    background: #0e7490;
    color: #ffffff;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: transparent;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a5f3fc;
    border-color: transparent;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: var(--primary-100);
    background: var(--primary-100);
    color: #ffffff;
}
.p-checkbox.p-invalid > .p-checkbox-box {
    border-color: #e24c4c;
}
.p-multiselect-select-all {
    display: none;
}
.p-multiselect-filter {
    border: 1px solid var(--black-25);
    padding: 10px 16px 10px 40px;
    border-radius: 10px
}
.p-multiselect-filter::placeholder{
    content: "Enter your number";
}
.p-multiselect:active, .p-component:active, .p-inputwrapper:active, .p-inputwrapper-filled:active, .p-inputwrapper-active:active {
    border: transparent !important;
}
